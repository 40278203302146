import styled from 'styled-components';
import { Link } from 'gatsby';

export const Div = styled.div`
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 250px;

  ${({ theme }) => theme.media.tablet} {
    width: 100%;
    max-width: 1024px;
    position: relative;
    overflow: hidden;
    height: 350px;
  }

  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    max-width: 100%;
    position: relative;
    height: 400px;
  }
`;

export const Img = styled.img`
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: ${({ isLeft }) => (isLeft ? '50% 0%' : '47% 0')};
  overflow: hidden;
  z-index: -1;

  ${({ theme }) => theme.media.tablet} {
    width: 140%;
    height: 350px;
    max-height: 400px;
    object-fit: cover;
    object-position: ${({ isRight }) => (isRight ? '85% 0%' : '90% 0')};
    overflow: hidden;
    z-index: -1;
  }

  ${({ theme }) => theme.media.Bigtablet} {
    width: 140%;
    height: 350px;
    max-height: 400px;
    object-fit: cover;
    object-position: ${({ isRight }) => (isRight ? '85% 0%' : '125% 25%')};
    overflow: hidden;
    z-index: -1;
  }

  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: 50% 50%;

    z-index: -1;
  }

  ${({ theme }) => theme.media.large} {
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: 50% 55%;
    z-index: -1;
  }
`;
export const ImgSlider = styled(Img)`
  ${({ theme }) => theme.media.desktop} {
    width: 100%;
    height: 400px;
    object-fit: cover;
    object-position: 50% 50%;

    z-index: -1;
  }

  ${({ theme }) => theme.media.large} {

    width: 100%;
    height: 400px;
    object-fit: contain;
    object-position: 50% 50%;
    z-index: -1;
  }
`;
export const StyledSlid = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  font-size: ${({ theme }) => theme.font.size.xs};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  position: relative;

  ${({ theme }) => theme.media.desktop} {
    width: ${({ isBig }) => (isBig ? '90%' : '80%')};
    display: flex;
    font-size: ${({ theme }) => theme.font.size.xs};
    margin-left: 6%;
  }

  ${({ theme }) => theme.media.large} {
    width: ${({ isBig }) => (isBig ? '75%' : '65%')};
    display: flex;
    font-size: ${({ theme }) => theme.font.size.xs};
    margin-left: 0;
    justify-content: center;
  }
`;
export const StyledSlaidInner = styled.div`
  width: 50%;
  height: ${({ isSmall }) => (isSmall ? '85%' : '90%')};
  height: ${({ isSmaller }) => (isSmaller ? '72%' : '90%')};
  margin-top: ${({ isTop }) => (isTop ? '4%' : '1%')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;

  ${({ theme }) => theme.media.tablet} {
    width: 55%;
    height: 80%;
    margin-top: 5%;
    padding-right: 2%;
  }

  ${({ theme }) => theme.media.desktop} {
    width: ${({ isWide }) => (isWide ? '50%' : '50%')};
    justify-content: center;
    align-items: flex-start;
    margin-left: 0;
    text-align: center;
    align-items: center;
  }

  ${({ theme }) => theme.media.large} {
    width: ${({ isWide }) => (isWide ? '40%' : '30%')};
    margin-left: ${({ isLeft }) => (isLeft ? '0%' : '4%')};
    text-align: center;
    margin-top: 3%;
  }
`;
export const StyledSlaidInnerEbook = styled(StyledSlaidInner)`
  ${({ theme }) => theme.media.tablet} {
    padding-right: 5%;
    margin-top: 3%;
  }

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    align-items: center;
    padding-right: 8%;
  }

  ${({ theme }) => theme.media.large} {
    width: 45%;
    margin-left: 0;
    margin-top: 3%;
    padding-right: 11%;
    text-align: center;
  }
`;

export const StyledSlaidInnerKinder = styled(StyledSlaidInner)`
  width: 50%;
  height: ${({ isSmall }) => (isSmall ? '85%' : '90%')};
  height: ${({ isSmaller }) => (isSmaller ? '72%' : '90%')};
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;

  ${({ theme }) => theme.media.tablet} {
    width: 55%;
    height: 70%;
    margin-top: 7%;
    padding-right: 11%;
  }

  ${({ theme }) => theme.media.desktop} {
    width: 50%;
    height: 65%;
    padding-right: 0;
    margin-right: 6%;
    margin-top: 8%;
    text-align: center;
    justify-content: space-around;
  }

  ${({ theme }) => theme.media.large} {
    width: 35%;
    height: 70%;
    padding-right: 8%;
    margin-right: 0;
    margin-top: 5%;
    text-align: center;

    justify-content: space-around;
  }
`;

export const StyledSlaidInnerHelp = styled(StyledSlaidInner)`
  width: 50%;
  height: ${({ isSmall }) => (isSmall ? '85%' : '90%')};
  height: ${({ isSmaller }) => (isSmaller ? '72%' : '90%')};
  margin-top: 2%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;

  ${({ theme }) => theme.media.tablet} {
    width: 50%;
    height: 78%;
    margin-top: 5%;
    padding-right: 5%;

  }

  ${({ theme }) => theme.media.desktop} {
    width: 45%;
    padding-right: 4%;
    margin-right: 4%;
    margin-top: 4%;
    height: 220px;
    text-align: center;
    justify-content: space-around;

  }

  ${({ theme }) => theme.media.large} {
    width: 38%;
    padding-right: 0;
    margin-right: 4%;
    margin-left: 0;
    margin-top: 4%;
    height: 220px;
    text-align: center;
    justify-content: space-around;

  }
`;

export const StyledSlaidInnerShop = styled.div`
  width: ${({ isSmall }) => (isSmall ? '50%' : '60%')};
  height: 100%;
  max-width: 300px;
  margin-bottom: 10%;
  margin-left: ${({ isSmall }) => (isSmall ? '47%' : '49%')};
  margin-right: 3%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  ${({ theme }) => theme.media.tablet} {
    margin-bottom: 0;
    margin-top: 5%;
  }

  ${({ theme }) => theme.media.desktop} {
    width: ${({ isSmall }) => (isSmall ? '37%' : '40%')};
    max-width: 350px;
    margin-left: ${({ isSmall }) => (isSmall ? '50%' : '45%')};
    margin-top: 3%;
  }

  ${({ theme }) => theme.media.large} {
    width: ${({ isSmall }) => (isSmall ? '25%' : '45%')};
    padding-right: 15%;
    margin-left: ${({ isSmall }) => (isSmall ? '53%' : '53%')};
    margin-top: 3%;
    max-width: none;
  }
`;
export const ImgSmall = styled.img`
  width: 58%;
  height: auto;
  object-fit: contain;
  object-position: 50% 50%;
  margin-bottom: 0;
  margin-right: 0;
  height: auto;

  ${({ theme }) => theme.media.tablet} {
    width: 61%;
    height: auto;
    margin-top: 2%;
  }

  ${({ theme }) => theme.media.desktop} {
    width: 65%;
    height: 350px;
    margin-top: 0%;
    margin-right: 0%;
    margin-left: ${({ isLeft }) => (isLeft ? '0%' : '0')};
  }

  ${({ theme }) => theme.media.large} {
    width: 660px;
    height: 380px;
    margin-right: 2%;
    object-fit: cover;
    object-position: 50% 50%;
    margin-left: ${({ isLeft }) => (isLeft ? '2%' : '0')};
  }
`;
export const ImgEbook = styled(ImgSmall)`
  width: 58%;
  height: auto;
  object-fit: contain;
  object-position: 50% 50%;
  margin-bottom: 0px;
  margin-right: 0;

  ${({ theme }) => theme.media.tablet} {
    width: 60%;
    height: auto;
    margin-top: 2%;
  }

  ${({ theme }) => theme.media.desktop} {
    width: 60%;
    object-fit: contain;
    height: 350px;
    margin-top: 0%;
    margin-right: 0%;
    margin-left: ${({ isLeft }) => (isLeft ? '2%' : '0')};
  }

  ${({ theme }) => theme.media.large} {
    width: 50%;
    height: 100%;
    margin-right: 0;
    margin-top: 2%;
    object-fit: contain;
    object-position: 100% 50%;
  }
`;

export const ImgSmall2 = styled(ImgSmall)`
  width: 54%;
  object-position: 50% 50%;

  ${({ theme }) => theme.media.tablet} {
    width: 50%;
    height: 95%;
    margin-right: 3%;
    margin-left: 2%;
    margin-top: 2%;
  }

  ${({ theme }) => theme.media.desktop} {
    width: ${({ small }) => (small ? '34%' : '60%')};
    height: ${({ small }) => (small ? '350px' : '360px')};
    margin-right: 2%;
    margin-top: ${({ small }) => (small ? '3%' : '0')};
    margin-left: ${({ isLeft }) => (isLeft ? '0%' : '0%')};
    margin-bottom: 2%;
    object-position: 100% 50%;
    object-fit: contain;
  }

  ${({ theme }) => theme.media.large} {
    width: ${({ small }) => (small ? '27%' : '530px')};
    height: ${({ small }) => (small ? '370px' : '390px')};
    margin: 0;
    object-position: 100% 50%;
    object-fit: contain;
    padding-left: 1%;
  }
`;
export const ImgHelp = styled(ImgSmall)`
  width: 40%;
  height: auto;
  object-fit: contain;
  object-position: 50% 50%;
  height: auto;
  margin: 2% 5%;

  ${({ theme }) => theme.media.tablet} {
    width: 38%;
    height: auto;
    object-fit: contain;
    object-position: 100% 0%;
    height: 80%;
    margin-top: 4%;
  }

  ${({ theme }) => theme.media.desktop} {
    width: 42%;
    height: 80%;
    object-fit: contain;
    object-position: 50% 50%;
    height: auto;
    padding-left: 5%;
  }

  ${({ theme }) => theme.media.large} {
    width: 42%;
    margin: 0;
    margin-top: 3%;
    object-fit: contain;
    object-position: 50% 100%;
    height: 90%;
    margin-left: 1%;
    margin-right: 1%;
    padding-left: 14%;

  }
`;
export const H1 = styled.h1`
  font-size: ${({ isBig }) =>
          isBig
                  ? ({ theme }) => theme.font.size.xxm
                  : ({ theme }) => theme.font.size.s};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  width: 100%;
  line-height: 1.3;
  padding-top: 2px;
  font-weight: 600;
  margin-top: ${({ isTop }) => (isTop ? '10px' : '0')} !important;
  margin-bottom: ${({ isDown }) => (isDown ? '10px' : '0')} !important;

  ${({ theme }) => theme.media.desktop} {
    padding: 3px 0px;
    font-size: ${({ theme }) => theme.font.size.xs};
  }
`;

export const P = styled.p`
  font-size: ${({ theme }) => theme.font.size.xxs};
  width: 100%;
  line-height: 1.3;
  padding-top: 2px;
  display: none;
  margin-top: ${({ isTop }) => (isTop ? '15px' : '0')};

  ${({ theme }) => theme.media.phonetextbooks} {
    display: block;
    margin-bottom: 0;
  }

  ${({ theme }) => theme.media.desktop} {
    padding: 3px 0px;
    font-size: ${({ theme }) => theme.font.size.xs};
  }
`;

export const A = styled.a`
  margin-top: ${({ isTop }) => (isTop ? '15px' : '0')};
  margin-top: ${({ isToper }) => (isToper ? '10%' : '0')};
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);
  font-size: ${({ theme }) => theme.font.size.xxs};
  padding: 10px 7px;

  ${({ theme }) => theme.media.tablet} {
    font-size: ${({ theme }) => theme.font.size.xs};
  }

  ${({ theme }) => theme.media.desktop} {
    border: none;
    margin-left: none;
    margin-top: ${({ isTop }) => (isTop ? '105px' : '0')};
    margin-top: ${({ isToper }) => (isToper ? '0%' : '0')};
    font-size: ${({ theme }) => theme.font.size.xs};
    font-family: ${({ theme }) => theme.font.family.Montserrat};
  }
`;

export const Button = styled.button`
  display: none;
  margin-top: 0;
  font-size: ${({ theme }) => theme.font.size.xxs};
  border: none;
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);
  padding: 8px 8px;

  ${({ theme }) => theme.media.tablet} {
    font-size: ${({ theme }) => theme.font.size.xs};
  }

  ${({ theme }) => theme.media.desktop} {
    margin-left: none;
    margin-top: ${({ isTop }) => (isTop ? '105px' : '0')};
    font-size: ${({ theme }) => theme.font.size.xs};
    font-family: ${({ theme }) => theme.font.family.Montserrat};

    &:hover {
      background-color: ${({ theme }) => theme.blue};
      transform: scale(1.1);
    }
  }
`;

export const Links = styled(Link)`
  font-size: ${({ theme }) => theme.font.size.xxs};
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.3);

  ${({ theme }) => theme.media.tablet} {
    font-size: ${({ theme }) => theme.font.size.xs};
  }

  ${({ theme }) => theme.media.desktop} {
    font-size: ${({ theme }) => theme.font.size.xs};
    font-family: ${({ theme }) => theme.font.family.Montserrat};
  }
`;
export const H1inLine = styled(H1)`
  display: inline-block;
  min-width: 132px;
`;

export const StyledMobileH1 = styled(H1)`
  display: block;

  ${({ theme }) => theme.media.phonetextbooks} {
    display: none;
  }
`;
export const StyledKinderH1 = styled(H1)`
  display: none;

  ${({ theme }) => theme.media.phonetextbooks} {
    display: block;
  }
`;
export const Div2 = styled.div`
  width: 100%;
  height: 250px;
  background-image: url('https://old.wydawnictwokatechetyczne.pl/assets/sklep.jpg');
  background-position: 44% 50%;
  background-size: cover;
  background-repeat: no-repeat;

  ${({ theme }) => theme.media.tablet} {
    height: 350px;
  }

  ${({ theme }) => theme.media.desktop} {
    height: 400px;
  }
`;

export const Plast = styled(P)`
  @media (max-width: 517px) {
    display: none;
  }
`;