import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import PropTypes from "prop-types";

const StyleWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: auto;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.77);
  z-index:9999;
`

const Div = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px 80px 50px;
  background-color: white;
  overflow-y: auto;
`
const StyledDiv = styled.div`
  width: 80%;
  height: calc(100vh - 10%);
  padding: 20px 5px;
  top: 5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 25px;
  background-color: white;
  box-shadow: 0 20px 40px -5px rgba(#1e58ff, 0.3);
  position: fixed;
  border: 1px rgba(0, 0, 0, 0.55) solid;
`

const StyledP = styled.div`
  font-size: ${({ theme }) => theme.font.size.ss};
  line-height: 1.5;
  color: ${({ theme }) => theme.blackfont};
  margin-bottom: 20px;
  font-weight: 500;
  overflow-y: auto;
`
const Button = styled.button`
  width: 37px;
  height: 37px;
  background: #1e58ff;
  border: none;
  position: absolute;
  right: 50px;
  top: 30px;
  &::before,
  &::after {
    content: '';
    width: 25px;
    height: 2px;
    position: absolute;
    background: white;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    transform-origin: 50%;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`

const CookieModal = ({ closeModalFn }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsCookie {
        nodes {
          title
          description
          id
        }
      }
    }
  `)

  return (
    <StyleWrapper>
      {data.allDatoCmsCookie.nodes.map(Cookie => (
        <StyledDiv key={Cookie.id}>
          <Div>
            <Button onClick={closeModalFn} />
            <StyledP
              key={Cookie.id}
              dangerouslySetInnerHTML={{ __html: Cookie.description }}
            />
          </Div>
        </StyledDiv>
      ))}
    </StyleWrapper>
  )
}
CookieModal.propTypes = {
  closeModalFn: PropTypes.bool,

}

CookieModal.defaultProps ={
  closeModalFn: false,

}
export default CookieModal
