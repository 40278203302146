import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import styled from "styled-components";
import "./StyleModal.css"


const StyledModal = styled.div`
  width: 100%;
  z-index: 88888888;
  position: relative;
  display: none;

  ${({theme}) => theme.media.desktop} {
    width: 1000px;
    border: 1px solid red;
  }
`;

const Button = styled.button`
  width: 40px;
  height: 40px;
  top: 0;
  right: 15px;
  background: none;
  padding-left: 6px;

  z-index: 99999999;
  border: 2px solid black;
  border-radius: 10%;
  position: absolute;

  &:focus {
    outline: 2px auto black;
    -moz-outline-radius: 5px;

  }

  ${({theme}) => theme.media.tablet} {
    width: 50px;
    height: 50px;

    background: none;
    z-index: 99999999;
    padding-left: 10px;
    margin-top: 35px;
    margin-right: 10px;
    margin-left: 0;

    &:focus {
      outline: 2px auto black;
      -moz-outline-radius: 5px;

    }
  }

  ${({theme}) => theme.media.desktop} {
    width: 40px;
    height: 40px;
    margin: 0;
    top: 0;
    right: 0;
    background: none;
    z-index: 9999999999;
    padding-left: 7px;
    transform: scale(0.85);

    &:focus {
      outline: 2px auto black;
      -moz-outline-radius: 5px;

    }
  }
`;
const ButtonInner = styled.div`
  position: relative;


  ::before, ::after {
    content: '';
    position: absolute;
    display: flex;
    width: 25px;
    height: 2px;
    left: 0;
    background-color: black;
    transition: transform .25s .15s ease-in-out;
  }

  ::before {
    top: -7px;
    transform: translateY(7px) rotate(45deg);
  }

  ::after {
    top: 7px;
    left: 0;
    transform: translateY(-7px) rotate(-45deg);
  }
`;
const StyledInner = styled.div`
  display: grid;
  place-items: center;
  position: relative;

  p {
    font-family: ${({theme}) => theme.font.family.Montserrat};
    text-align: center;
    margin-top: 70px;
  }
`;

const ModalInpost = ({isModalOpen, toogleModalOpenClose, ...props}) => {
  Modal.setAppElement('#___gatsby')

  console.log(props);
  return (
    <StyledModal>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={toogleModalOpenClose}

        className="Modal"

      >
        <StyledInner>

          <Button onClick={toogleModalOpenClose}>
            <ButtonInner/>
          </Button>

          <h2>Modal</h2>
        </StyledInner>
      </Modal>
    </StyledModal>
  );
};
ModalInpost.propTypes = {
  isModalInpostOpen: PropTypes.bool,
  setModalInpostOpen: PropTypes.func,


}

ModalInpost.defaultProps = {
  isModalOpen: false,

}
export default ModalInpost
