import React, {useState} from "react";
import styled, { css } from "styled-components";
import { useStaticQuery, graphql } from "gatsby";

const StyleWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.body};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  padding-bottom: 30px;
`;
const StyledNews = styled.div`
  width: 90%;
  height: auto;
  ${({ theme }) => theme.media.desktop} {
    width: 70%;
    height: auto;
    max-width: 1100px;
    margin-top: 10px;
  }
  ${({ redZone }) =>
    redZone &&
    css`
      padding: 15px 30px;
      background: red;
      opacity: 0.85;
      color: white;
      box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.3);
      border-radius: 15px;
      margin-bottom: 10px;
    `}
`;
const StyledTitle = styled.div`
  width: 100%;
  max-width: 1160px;
  margin: 10px 0;
`;

const TitleH1 = styled.h1`
  font-size: ${({ theme }) => theme.font.size.l};
  font-weight: 600;
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  color: ${({ theme }) => theme.blue};
  border-left: 4px solid ${({ theme }) => theme.green};
  padding-left: 12px;
  margin: 15px 0 5px 15px;
  ${({ theme }) => theme.media.desktop} {
    font-size: ${({ theme }) => theme.font.size.l};
    font-weight: 600;
    font-family: ${({ theme }) => theme.font.family.Montserrat};
    color: ${({ theme }) => theme.blue};
    border-left: 4px solid ${({ theme }) => theme.green};
    padding-left: 12px;
    margin: 20px 0 10px 15px;
  }
`;

const Img = styled.img`

  width: ${({Small}) => Small ?  "130px" : "170px" };
  height: ${({Small}) => Small ?  "180px" : "240px" };
  display: flex;
  flex: 1;
  margin-bottom: 15px ;
  ${({ theme }) => theme.media.tablet} {
    width: ${({Small}) => Small ?  "130px" : "170px" };
    height: ${({Small}) => Small ?  "180px" : "240px" };
    margin-right: 1em;

  }
  ${({ theme }) => theme.media.desktop} {
    margin-right: 5px;
    /*width: 120px;*/
    width: ${({Small}) => Small ?  "135px" : "170px" };  
    height: ${({Small}) => Small ?  "190px" : "240px" };
    z-index: 1;
    padding: 10px;

    &:hover {
      display: block;
      opacity: 0.5;
   
    }
  }
`;
const Img2 = styled.img`
  display: none;
  ${({ theme }) => theme.media.tablet} {
    display: none;
  }
  ${({ theme }) => theme.media.desktop} {
    width: ${({Small}) => Small ?  "115px" : "150px" };
    height: ${({Small}) => Small ?  "170px" : "220px" };
    padding: 15% 15%;
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 0;
    display: block;
    background-color: rgba(0, 0, 0, 0.3);
    object-fit: scale-down;
    object-position: 50% 50%;
    z-index: 1;
    &:hover {
      display: block;
      opacity: 1;
    }
  }
 
`;

const H1 = styled.h1`
  font-size: ${({ theme }) => theme.font.size.xm};
  font-weight: 600;
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  color: ${({ theme }) => theme.blue};
  margin: 15px 0 5px 0;
  ${({ redZone }) =>
    redZone &&
    css`
      color: white;
    `}
`;

const StyledP = styled.div`
  font-size: ${({ theme }) => theme.font.size.xs};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  margin: 0.5em 0 0.5em 0;
  font-weight: 400;
  line-height: 1.5;
p{
  a{
          text-decoration: none;}
}
  ${({ redZone }) =>
    redZone &&
    css`
      font-size: ${({ theme }) => theme.font.size.s};
      font-family: ${({ theme }) => theme.font.family.Montserrat};
      margin: 5px 0;
      overflow: hidden;
      word-wrap: unset;
      font-weight: 500;
      line-height: 1.7;
      color: white;
      a{
        color:white;
        font-weight: bold;
      }
    `}
`;

const StyledData = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;
const Images = styled.div`
  padding: 10px 0;
display: flex;
  flex-direction: column;
  align-items:center;
justify-content: center;
  ${({ theme }) => theme.media.tablet} {
    flex-direction: row;
  }

 ${({ theme }) => theme.media.desktop} {
   flex-direction: row;
  margin-left:5%;
   padding: 10px 0;
  justify-content: flex-start;
 }

`;
const BoxPhotos = styled.a`
  width: auto;
  position: relative;
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;

const P = styled.p`
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  font-size: ${({ theme }) => theme.font.size.xxxs};
  font-family: ${({ theme }) => theme.font.family.Montserrat};

`;
const StyledButton = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 15px 0;
`;
const Button = styled.button`
  width: 300px;
  height: 45px;
  border-radius: 8px;
  font-size: ${({ theme }) => theme.font.size.s};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  border: none;
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.3);
  background-color: white;
  font-weight: 600;
  transition: all 0.3s ease;
  &:hover {
    background-color: rgba(252, 246, 227, 0.8);
    font-size: ${({ theme }) => theme.font.size.xxxm};
  }
`;

const News = ({toogleModalImagesOpen }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsNews(sort: { fields: sort }) {
        nodes {
          id
          redZone
          title
          description
          buttontext
          nextdata {
            ... on DatoCmsHeadtitletml {
              id
              headlinetxt
            }
            ... on DatoCmsTexthtml {
              id
              texthtml
            }
            ... on DatoCmsImgafew {
              id
              imgsdata {
                title
                filename
                customData
                alt
              }
            }
          }
        }
      }
    }
  `);



  return (
    <StyleWrapper>
      <StyledTitle>
        <TitleH1>AKTUALNOŚCI</TitleH1>
      </StyledTitle>
      {data.allDatoCmsNews.nodes.map((New) => (
        <StyledNews key={New.id} redZone={New.redZone}>
          {New.title ? (<StyledData>
            <H1 redZone={New.redZone}>{New.title}</H1>
          </StyledData>): ''}
          {New.description ? (
          <StyledP redZone={New.redZone}>{New.description}</StyledP>): null}

          <StyledData redZone={New.redZone}>
            {New.nextdata.map((item) => {
              const itemKey = Object.keys(item)[1];

              switch (itemKey) {
                case "headlinetxt":
                  return (
                    <H1 redZone={New.redZone}
                      key={item.id}
                      dangerouslySetInnerHTML={{ __html: item.headlinetxt,
                      }}
                    />
                  );
                case "texthtml":
                  return (
                    <StyledP
                      redZone={New.redZone}
                      key={item.id}
                      dangerouslySetInnerHTML={{ __html: item.texthtml }}
                    />
                  );
                case "imgsdata":
                  // eslint-disable-next-line no-case-declarations
                  const foto = item.imgsdata.map((i) => (
                    <BoxPhotos key={i.filename} target="_blank" href={i.customData.ownUrl}>
                    <Img src={i.customData.ownUrl} alt={i.customData.Small} Small={i.customData.Small} />
                      <Img2
                        Small={i.customData.Small}
                        src="https://old.wydawnictwokatechetyczne.pl/assets/eye_icon.png"
                        alt="oko"
                      />
                      <P>{i.title}</P>
                    </BoxPhotos>
                  ));
                  return <Images key={item.id}>{foto}</Images>
                default:
                  return null;
              }
            })}

            {New.buttontext ? (
              <StyledButton>
                <Button >{New.buttontext}</Button>
              </StyledButton>
            ): null}
          </StyledData>
        </StyledNews>
      ))}
    </StyleWrapper>
  );
};

export default News;
