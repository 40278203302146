import React from 'react'
import CookieConsent from 'react-cookie-consent'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Button = styled.button`
  width: auto;
  border: none;
  padding: 0;
  margin: 1px 7px;
  background-color: rgba(0, 90, 180, 1);
  color: rgba(120, 200, 40, 1);
  font-weight: 500;
  border-bottom: 1px solid rgba(120, 200, 40, 1);
  transition: all 0.3s 0.3s ease-in;
  &:hover {
    transform: scale(1.06);
    color: rgba(246, 150, 0, 0.8);
    font-weight: 700;
  }
`
const Cookies = ({ openModalFn }) => (
  <CookieConsent
    location="bottom"
    buttonText="Akceptuję"
    cookieName="myAwesomeCookieName2"
    style={{ background: 'rgba(0,90,180,1)', borderTop: '2px white solid' }}
    buttonStyle={{
      color: 'White',
      background: 'rgba(120,200,40,1)',
      fontSize: '15px',
      fontWeight: 'bold',
      marginRight: '12vw',
    }}
    expires={150}
  >
    <p style={{ marginLeft: '11vw' }}>
      Strona korzysta z plików cookies w celu realizacji usług i zgodnie z
      <Button onClick={openModalFn} type="button">
        Polityką Plików Cookies.
      </Button>
      Możesz określić warunki przechowywania lub dostępu do plików cookies w
      Twojej przeglądarce.
    </p>
  </CookieConsent>
)

Cookies.propTypes = {
  openModalFn: PropTypes.func,
}

Cookies.defaultProps = {
  openModalFn: false,
}
export default Cookies
