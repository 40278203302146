import React from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyleWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: auto;
  overflow: hidden;
  position: fixed;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.77);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  ${({ theme }) => theme.media.desktop} {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Div = styled.div`
  width: 90%;
  height: 90%;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.media.desktop} {
    /* width: 80%;
    height: auto;
    min-height: 300px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
`;

const Div1 = styled.div`
  width: 80%;
  height: auto;
  flex-direction: column;
  min-height: 300px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.media.desktop} {
    width: 95%;
    height: auto;
    min-height: 300px;
    padding: 20px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
`;
const StyledDiv = styled.div`
  width: 95%;
  min-width: 250px;
  height: auto;
  min-height: 500px;
  max-height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 20px 20px -5px rgba(#1e58ff, 0.3);
  position: fixed;
  z-index: 1;
  border: 1px rgba(0, 0, 0, 0.55) solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.media.desktop} {
    width: 75%;
    min-width: 750px;
    height: calc(100vh - 15%);
    padding: 20px 5px;
    top: 5%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 20px 40px -5px rgba(#1e58ff, 0.3);
    position: fixed;
    z-index: 1;
    border: 1px rgba(0, 0, 0, 0.55) solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;


const Button = styled.button`
  width: 32px;
  height: 32px;
  background: #1e58ff;
  border: none;
  position: absolute;
  right: 15px;
  top: 15px;

  &::before,
  &::after {
    content: '';
    width: 22px;
    height: 2px;
    position: absolute;
    background: white;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    transform-origin: 50%;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  ${({ theme }) => theme.media.desktop} {
    width: 37px;
    height: 37px;
    background: #1e58ff;
    border: none;
    position: absolute;
    right: 50px;
    top: 30px;

    &::before,
    &::after {
      content: '';
      width: 25px;
      height: 2px;
      position: absolute;
      background: white;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      transform-origin: 50%;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }

`;


const StyledIframe = styled.iframe`
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  border: none;
`;

const NewsModal = ({ closeModalNewsFn }) => (
  <StyleWrapper>
    <StyledDiv>
      <Div1>

        <Button onClick={closeModalNewsFn} />
        <Div>
          <StyledIframe width='100%' height='100%'
                        src='https://old.wydawnictwokatechetyczne.pl/pliki/movie.mp4'
                        title='film instruktażowy'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope'
                        allowFullScreen='true'
                        webkitallowfullscreen='true'
                        mozallowfullscreen='true' />
        </Div>

      </Div1>


    </StyledDiv>
  </StyleWrapper>
);

NewsModal.propTypes = {
  closeModalNewsFn: PropTypes.bool
};

NewsModal.defaultProps = {
  closeModalNewsFn: false
};
export default NewsModal;
