import React, {useState} from 'react'
import MainTemplate from 'templates/MainTemplate'
import Slider from 'components/Slider/Slider'
import Sticky from 'components/Sticky/Sticky'
import News from 'components/News/News'
import Cookies from 'components/Cookies/Cookies'
import SEO from 'components/SEO/seo'
import CookieModal from 'components/Cookies/CookieModal'
import SliderModal from 'components/Slider/SliderModal'
import NewsModal from 'components/News/NewsModal'
import ModalImages from '../components/News/ModalImages';



const IndexPage = () =>{
  const [isModalOpen,setIsModalOpen] = useState(false);
  const [isModalOpenNews,setIsModalOpenNews] = useState(false);
  const [isModalSliderOpen, setIsModalSliderOpen] = useState(false);
  const [isModalImagesOpen, setModalImagesOpen] = useState(false)
  const toogleOpenModal=() =>{
    setIsModalOpen(true);
  }
  const toogleOpenlSlider=() =>{
    setIsModalSliderOpen(true);
  }

  const toogleOpenlModalNews=() =>{
    setIsModalOpenNews(true);
  }



  const toogleModalImagesOpen = ({props}) => {
    console.log({props});
    console.log(isModalImagesOpen);
    console.log("toogleModalImagesOpen");
    setModalImagesOpen(!isModalImagesOpen)
  }

  const tooglecloseModal=() =>{
    setIsModalSliderOpen(false);
    setIsModalOpenNews(false);
    setIsModalOpen(false);
    setModalImagesOpen(false);
  }

  return (
    <MainTemplate>

      <SEO title={process.env.GATSBY_CITY} />
      <Slider openModalSliderFn={toogleOpenlSlider} openModalNewsFn={toogleOpenlModalNews}/>
      <Sticky />
      <News toogleModalImagesOpen={toogleModalImagesOpen } />

      {isModalOpenNews ? (
        <NewsModal closeModalNewsFn={tooglecloseModal} />
      ): null}
      {isModalImagesOpen ? (
        <ModalImages isModalOpen={isModalImagesOpen} toogleModalOpenClose={tooglecloseModal} />
      ): null}
      <Cookies openModalFn={toogleOpenModal} />
      {isModalSliderOpen ? (
        <SliderModal closeModalSliderFn={tooglecloseModal} />
      ): null}
      {isModalOpen ? <CookieModal closeModalFn={tooglecloseModal} /> : null}
    </MainTemplate>
  )
}

export default IndexPage
