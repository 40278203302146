import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyleWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: auto;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: auto;
  background-color: rgba(255, 255, 255, 0.77);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  ${({ theme }) => theme.media.desktop} {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Div = styled.div`
  width: 90%;
  height: auto;
  min-height: 280px;
  top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.media.desktop} {
    width: 80%;
    height: auto;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const Div1 = styled.div`
  width: 80%;
  height: auto;
  flex-direction: column;
  min-height: 300px;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.media.desktop} {
    width: 80%;
    height: auto;
    min-height: 300px;
    padding: 50px 80px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const StyledDiv = styled.div`
  width: 90%;
  min-width: 250px;
  height: auto;
  max-height: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 18px;
  background-color: white;
  box-shadow: 0 20px 40px -5px rgba(#1e58ff, 0.3);
  position: fixed;
  z-index: 1;
  border: 1px rgba(0, 0, 0, 0.55) solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.media.desktop} {
    width: 52%;
    min-width: 750px;
    height: calc(100vh - 40%);
    padding: 20px 5px;
    top: 5%;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 20px 40px -5px rgba(#1e58ff, 0.3);
    position: fixed;
    z-index: 1;
    border: 1px rgba(0, 0, 0, 0.55) solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Img = styled.img`
  width: 190px;
  height: 260px;
  ${({ theme }) => theme.media.desktop} {
    width: 200px;
    height: 280px;
  }
`;
const Button = styled.button`
  width: 32px;
  height: 32px;
  background: #1e58ff;
  border: none;
  position: absolute;
  right: 15px;
  top: 15px;
  &::before,
  &::after {
    content: "";
    width: 22px;
    height: 2px;
    position: absolute;
    background: white;
    display: block;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s ease;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    transform-origin: 50%;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
  ${({ theme }) => theme.media.desktop} {
    width: 37px;
    height: 37px;
    background: #1e58ff;
    border: none;
    position: absolute;
    right: 50px;
    top: 30px;
    &::before,
    &::after {
      content: "";
      width: 25px;
      height: 2px;
      position: absolute;
      background: white;
      display: block;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.3s ease;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
      transform-origin: 50%;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }
  }
`;

const A = styled.a`
  width: auto;

  ${({ theme }) => theme.media.desktop} {
    width: auto;
    padding: 30px 50px;
  }
`;

const StyledH1 = styled.div`
  width: 78vw;
  margin-top: 15px;
  margin-right: 5px;
  ${({ theme }) => theme.media.desktop} {
    width: 90%;
  }
`;
const H1 = styled.h1`
  font-size: ${({ theme }) => theme.font.size.s};
  font-family: ${({ theme }) => theme.font.family.Montserrat};
  width: 100%;
  text-align: center;
  font-weight: 600;
  line-height: 1.3;
  ${({ theme }) => theme.media.desktop} {
    font-size: ${({ theme }) => theme.font.size.xm};
    font-family: ${({ theme }) => theme.font.family.Montserrat};
    width: 100%;
    font-weight: 600;
    text-align: center;
    line-height: 1.3;
  }
`;
const SliderModal = ({ closeModalSliderFn }) => {
  const data = useStaticQuery(graphql`
    query {
      allDatoCmsPresentation {
        nodes {
          id
          title
          program {
            url
            alt
            filename
          }
        }
      }
    }
  `);

  return (
    <StyleWrapper>
      <StyledDiv>
        {data.allDatoCmsPresentation.nodes.map((ProgramOf) => (
          <Div1 key={ProgramOf.id}>
            <StyledH1>
              <H1>{ProgramOf.title}</H1>
            </StyledH1>
            <Button onClick={closeModalSliderFn} />
            <Div>
              {ProgramOf.program.map((prog) => (
                <Div key={prog.id}>
                  <A target="_blank" rel="noopener noreferrer" href={prog.alt}>
                    <Img src={prog.url} alt={prog.filename} />
                  </A>
                </Div>
              ))}
            </Div>
          </Div1>
        ))}
      </StyledDiv>
    </StyleWrapper>
  );
};
SliderModal.propTypes = {
  closeModalSliderFn: PropTypes.bool,
};

SliderModal.defaultProps = {
  closeModalSliderFn: false,
};
export default SliderModal;
